.gameItem {
  height: 35vw !important;
}

@media (min-width: 576px) {
  .gameItem {
    height: 20vw !important;
  }
}

@media (min-width: 768px) {
  .gameItem {
    height: 12vw !important;
  }
}

@media (min-width: 1800px) {
  .gameItem {
    height: 243px !important;
  }
}

@media (min-width: 1600px) {
  .gameItem {
    height: 196px !important;
  }
}

.ant-dropdown-menu-item-only-child {
  border-radius: 15px 15px !important;
}

.landingCont {
  max-width: 1500px;
}
@media (min-width: 2000px) {
  .landingCont {
    max-width: 1800px !important;
  }
}

.pageContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  background: #ffffff no-repeat fixed center center;
  background-size: cover;
}

.sa36 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-y: scroll;
  background-image: url('/images/sa36/index-background.jpg');
  background-size: cover;
}

@media (max-width: 576px) {
  .pageContainer {
    background-size: cover;
  }
}
.ant-select .ant-select-selector {
  border-radius: 20px !important;
}

.ant-select-dropdown {
  border-radius: 20px !important;
}

.ant-picker-panel-container {
  border-radius: 20px !important;
}

.ant-picker-ok {
  display: none !important;
}

.login-form .ant-input,
.login-form .ant-input-password {
  background: transparent !important;
  border-color: #464646;
  font-weight: 600;
  letter-spacing: 2px;
  text-indent: 10px;
}

.login-form .ant-input::placeholder,
.login-form .ant-input-password::placeholder {
  color: #464646;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  text-indent: 10px;
}

.sa36 .login-form .ant-input,
.sa36 .login-form .ant-input-password {
  background: white !important;
  border-color: #464646;
}

.sa36 .login-form .ant-btn.ant-btn-primary {
  background: linear-gradient(to left, #e8911c 0%, #7e5700 100%) !important;
  border-color: #464646;
}

.sa36 .ant-layout-footer {
  color: white;
}

.blackants {
  background: #121010;

  & .login-form .ant-input,
  .login-form .ant-input-password,
  .ant-dropdown-trigger,
  .ant-dropdown-trigger svg {
    border-color: #c5c5c5;
    color: #c5c5c5;
  }

  & .login-form .ant-btn.ant-btn-primary {
    background: #c90a01cc !important;
    border-color: #464646;
  }

  & .ant-layout-footer {
    color: #c5c5c5;
  }

  &.ant-dropdown {
    background: transparent;
  }

  & ul.ant-dropdown-menu {
    color: #c5c5c5;
    background: #121010;
    border: 1px solid #c5c5c5;
    border-radius: 20px;

    & .ant-dropdown-menu-item {
      color: #c5c5c5;
    }
    & .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
      background-color: #c90a01cc;
    }
  }
  & .ant-dropdown-trigger .ant-btn {
    background-color: #121010;
    border-color: #c5c5c5;
    & div {
      color: #c5c5c5;
    }
  }

  & .user-amount {
    color: #c5c5c5;
    border-color: #c5c5c5;
    & .user-amount-number {
      color: #c5c5c5;
    }
    & svg {
      color: #c5c5c5;
    }
  }

  & .ant-card.ant-card-bordered {
    background-color: #121010;
  }

  & .ant-picker.ant-picker-range {
    background-color: #121010;
    & .ant-picker-input input {
      color: #c5c5c5;
    }

    & .ant-picker-separator {
      color: #c5c5c5;
    }

    & .ant-picker-suffix {
      color: #c5c5c5;
    }

    & .ant-picker-clear {
      background-color: #121010;
      & span {
        color: #c5c5c5;
        border: none;
      }
    }
  }

  & .ant-btn {
    background: #121010 !important;
    color: #c5c5c5 !important;
    border: 1px solid #c5c5c5 !important;
    &:hover {
      background: #c90a01cc !important;
    }
  }

  & .ant-table {
    & .ant-table-thead > tr > th {
      background: #121010;
      color: #c5c5c5;
      border: 1px solid #c5c5c5;
    }

    & .ant-table-tbody > tr > td {
      background: #121010;
      color: #c5c5c5;
      border: 1px solid #c5c5c5;
    }

    & .ant-table-summary > tr > td {
      background: #121010;
      color: #c5c5c5;
      border: 1px solid #c5c5c5;
    }

    & .ant-table-row .ant-table-cell.ant-table-cell-row-hover {
      background: rgb(116 9 9);
    }

    & .ant-table-placeholder:hover td.ant-table-cell {
      background: #121010;
      & .ant-empty .ant-empty-normal {
        background: #121010;
      }
    }
    & .ant-empty-description {
      color: #c5c5c5;
    }
  }

  & .ant-page-header-heading-title {
    color: #c5c5c5;
  }

  & .ant-form label {
    color: #c5c5c5;
  }

  & .ant-form-item-control {
    & .ant-select .ant-select-selector {
      background: #121010;
      color: #c5c5c5;
      border: 1px solid #c5c5c5;
    }
    & .ant-input-affix-wrapper {
      background: #121010;
      color: #c5c5c5;
      border: 1px solid #c5c5c5;
      &.ant-input-affix-wrapper-status-error {
        background: #121010 !important;
        color: #c5c5c5 !important;
      }
      & .ant-input {
        background: #121010;
        color: #c5c5c5;
      }
    }
    & .ant-input-suffix span {
      color: #c5c5c5;
    }
  }

  & .ant-select-dropdown {
    background: #121010;
    border: 1px solid #c5c5c5;

    & .ant-select-item-option-content {
      color: #c5c5c5;
    }

    & .ant-select-item-option-active .ant-select-item-option-content {
      color: #121010;
    }

    & .ant-select-item-option-selected {
      background: #c90a01cc !important;
      color: #c5c5c5 !important;
    }
  }

  & .ant-picker-panel-container {
    background: #121010;
    border: 1px solid #c5c5c5;
    & .ant-picker-header {
      background: #121010;
      color: #c5c5c5;
    }
    & .ant-picker-content th,
    .ant-picker-content td,
    .ant-picker-header-super-prev-btn,
    .ant-picker-header-super-next-btn {
      color: #c5c5c5;
    }
    /*& .ant-picker-footer {*/
    /*  background: #121010;*/
    /*  color: #c5c5c5;*/
    /*}*/
    .ant-picker-cell.ant-picker-cell-disabled {
      background: #ffffff1a;
    }
  }

  &
    .ant-picker-time-panel-column
    .ant-picker-time-panel-cell
    .ant-picker-time-panel-cell-inner {
    color: #c5c5c5;
  }
}
